import React from 'react';
import './bot-detected.css';

const BotDetected = () => {
  return (
    <div className="bot-warning-container">
      <div className="bot-warning-content">
        <svg className="warning-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
        </svg>
        <h2>Automated Activity Detected</h2>
        <p>Our system has detected unusual automated behavior from your account.</p>
        <p>If you believe this is a mistake, please contact our support team:</p>
        <div className="support-options">
          <a href="mailto:support@doubtbuddy.com" className="support-button">
            Email Support
          </a>
        </div>
      </div>
    </div>
  );
};

export default BotDetected;