import React, { useEffect, useState, useRef, useCallback } from "react";
import io from "socket.io-client";
// import MathJaxComponent from "./mathJaxComponent";
import MathJax from "react-mathjax2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Camera } from "react-camera-pro";

import Cropper, { ReactCropperElement } from "react-cropper";
import SendIcon from "@mui/icons-material/Send";
import "cropperjs/dist/cropper.css";
import FreeFinish from "./FreeFinish";
import FreeFinish2 from "./FreeFinish2.js";
import { initMixpanel, setMixPanelDefaultProperties, trackEvent } from './mixPanel.js';

import { useParams } from "react-router-dom";
import { ask_another_doubt, attach_icon, cancel_attach, cancel_image, click_photo, explain_step, followup_question, no_feedback, other_Suggestion, SendButton, submit_image, Suggestion_1st, Suggestion_2nd, type_doubts, upload_document, yes_feedback } from "./mixPanelEventConstant.js";
import Loader from "./Loder";
import { Turnstile } from "@marsidev/react-turnstile";
import BotDetected from "./bot-detected/bot-detected.js";
const apiUrl = process.env.REACT_APP_API_URL || "https://localhost:3600";

const socket = io(apiUrl, {
  autoConnect: false
});

const Home = () => {

  const [returningApiKey, setReturningApiKey] = useState("");
  // const [file, setFile] = useState(null);
  const [textQuestion, setTextQuestion] = useState("");
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState("");
  const [queries, setQueries] = useState([]);
  const [selectedLine, setSelectedLine] = useState("");
  const [visibleSteps, setVisibleSteps] = useState({});
  const [stopRendering, setStopRendering] = useState(false);
  const [followUpData, setFollowUpData] = useState("");
  // const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showCropArea, setShowCropArea] = useState(false);
  const [commonQuestionSolutions, setCommonQuestionSolutions] = useState({});
  const [commonProcessing, setCommonProcessing] = useState("");
  // const [followupQuestionSolutions, setFollowupQuestionSolutions] = useState("");
  const [followupResponses, setFollowupResponses] = useState({});
  const [followupInputQuestion, setFollowupInputQuestion] = useState({});
  const [followupQuestion, setFollowupQuestion] = useState("");
  const [feedbackShow, setFeedbackShow] = useState(true);
  const [feedbackDataShow, setFeedbackDataShow] = useState(false);
  const [thanksFeedBack, setThanksFeedBack] = useState(false);
  const cfTurnstileRef = useRef(null);
  const [isValidUser, setIsValidUser] = useState(true);

  const [image, setImage] = useState(null);
  // const [cropData, setCropData] = useState(null);
  const [orgFileName, setOrgFileName] = useState("");
  // const [cameraOpened, setCameraOpened] = useState(false);
  const [hasCamera, setHasCamera] = useState(false);
  const [permissionDenied, setPermissionDenied] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [doubtId, setDoubtId] = useState("");
  const cropperRef = useRef(null);
  const fileInputRef = useRef(null);
  const cameraRef = useRef(null);
  const cancelButtonRef = useRef(null);
  const explanationRefs = useRef([]);
  const [showModal, setShowModal] = useState(false);
  const [FreeDoubt, setFreeDoubt] = useState(null);
  const [codeSnippet, setCodeSnippet] = useState(null);
  const useOld = false;
  const [notLogin, setNotLogin] = useState(true);
  const [freeFinishProps, setFreeFinishProps] = useState({});

  console.log({ loadTime: new Date().getTime() })


  const mathJaxConfig = {
    tex2jax: {
      inlineMath: [['$', '$'], ['\\(', '\\)']],
      displayMath: [['$$', '$$'], ['\\[', '\\]']],
      processEscapes: true
    },
    TeX: {
      extensions: ["AMSmath.js", "AMSsymbols.js"]
    }
  };

  const SafeMathJaxText = ({ content }) => {
    const sanitizeLatex = (text) => {
      if (!text) return '';
      return text
        .replace(/\\\\/g, '\\') 
        .replace(/\s*\$\s*/g, '$') 
        .replace(/\\vec\s*{([^}]*)}/g, '\\vec{$1}') 
        .replace(/\\hat\s*{([^}]*)}/g, '\\hat{$1}') 
        .trim();
    };

    return <MathJax.Text text={sanitizeLatex(content)} />;
  };



  // http://localhost:3000/?key=97e5f959-978d-401d-8b0e-2487561c4f2b
  const [partnerData, setPartnerData] = useState({
    Name: "",
    Questions: [],
    blankSubtitle: "Upload Your Screenshot and Get a Quick Solution!",
    blankTitle: "Unlock Learning with 24×7 Doubt Support",
    doubtIcon: "https://uat.chatbot.doubtbuddy.com/logoFrame.png",
    placeholderText: "Enter a question here",
    showAttachments: true,
    showFeedback: true,
    showQuestionImages:true,
    EmailId: "",
    ImageUrl: "",
  });

  const handleSocketEmit = async (eventName, data) => {
    cfTurnstileRef.current?.reset();
    const cf_token = await cfTurnstileRef.current?.getResponsePromise();
    if (cf_token) {
      socket.emit(eventName, { ...data, cf_token });
    } else {
      setIsValidUser(false);
    }
  }
  const handleSocketInit = async () => {
    socket.connect();
  }
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const apiKey = urlParams.get("key");
    const educatorId = urlParams.get("edu");
    const studentId = urlParams.get("stu");
    const token = urlParams.get("token");
    initMixpanel();
    let startTime = Date.now();
    console.log("Start time", startTime);

    socket.on("ack", () => {
      console.log("Socket id " + socket.id + " Ack on", Date.now());
      if (apiKey) {
        console.log("Login emit", Date.now());
        let payload = {
          apiKey: apiKey,
        }

        if (educatorId) {
          payload.educatorId = educatorId
        }
        if (studentId) {
          payload.studentId = studentId
        }
        if (token) {
          payload.token = token
        }
        handleSocketEmit("login", payload);
      }

      // socket.emit("login", {
      //   apiKey: apiKey,
      //   educatorId: educatorId,
      //   studentId: studentId,
      //   token:token
      // });
    })

    socket.on("cf_turnstile_error", () => {
      setIsValidUser(false);
    });
    socket.on("trial-expire", (message) => {
      if (message) {
        stopRenderingHandler();
        allClear()
        setShowModal(true);
        setFreeDoubt(message.data.FreeDoubt);
        setCodeSnippet(message.data.codeSnippet);
      }
    });

    socket.on("login-success", (message) => {
      console.log("login sucess on time", Date.now(), "Total time", Date.now() - startTime);
      if (message.status === true) {
        setNotLogin(false)
        const { apiKey, Partner } = message;
        const questions = Partner?.Questions?.length
          ? Partner.Questions
          : ["", "", "", ""];
        setPartnerData({ ...Partner, Questions: questions });
        setReturningApiKey(message.apiKey);
        setPartnerData(Partner);

        setFreeFinishProps({
          ApiKey: Partner.ApiKey,
          studentId: message.studentId
        })
        setMixPanelDefaultProperties({ partnerKey: Partner.ApiKey, studentId: message.studentId, educatorId: message.educatorId, partnerId: Partner._id })
      }
    });

    socket.on("processing-status", (data) => {
      if (data.status) {
        setProcessing(data.status);
        setLoading(true);
      }
    });

    socket.on("query-response-solution", (data) => {
      setQueries((prevQueries) => {
        const updatedQueries = [...prevQueries];
        updatedQueries[updatedQueries.length - 1].solution = data.content;
        return updatedQueries;
      });
      setLoading(false);
    });

    socket.on("image-url", (imageUrl) => {
      setQueries((prevQueries) => {
        const updatedQueries = [...prevQueries];
        updatedQueries[updatedQueries.length - 1].imageUrl = imageUrl;
        return updatedQueries;
      });
      console.log(imageUrl);
      setImage(null);
    });

    socket.on("question-text", (question) => {
      if (question) {
        setStopRendering(false);
      }
      setQueries((prevQueries) => {
        const updatedQueries = [...prevQueries];
        if (updatedQueries.length > 0) {
          updatedQueries[updatedQueries.length - 1].question = question;
        }
        return updatedQueries;
      });
    });

    socket.on("query-update-solution", (chunk) => {
      if (!stopRendering) {
        setQueries((prevQueries) => {
          const updatedQueries = [...prevQueries];
          updatedQueries[updatedQueries.length - 1].solution += chunk.chunk;
          return updatedQueries;
        });
      }
    });

    socket.on("final-solution", (data) => {
      if (data.status) {
        setLoading(false);
      }
      setDoubtId(data?.dountId ? data?.dountId : "")
      setFeedbackShow(false)
      setFeedbackDataShow(true)

    });

    socket.on("common-question-solution", (chunk) => {
      const { indexValue, commonSolution } = chunk;
      setCommonQuestionSolutions((prev) => ({
        ...prev,
        [indexValue]: commonSolution,
      }));
      setCommonProcessing("");
    });

    socket.on("doubt-end", () => {
      sendDataToAndroid()
      sendDataToFlutter()
    });

    socket.on("common-question-processing", (data) => {
      if (data.status) {
        setCommonProcessing(data.status);
      }
    });

    socket.on("followup-query-user", (data) => {
      setFollowupResponses((prevResponses) => {
        const updatedResponses = { ...prevResponses };
        if (!updatedResponses[data.followCompositeKey]) {
          updatedResponses[data.followCompositeKey] = [];
        }
        updatedResponses[data.followCompositeKey].push({
          user: data.user,
          bot: "",
        });
        return updatedResponses;
      });
      setFollowupQuestion("");
    });

    socket.on("followup-query-bot", (data) => {
      setFollowupResponses((prevResponses) => {
        const updatedResponses = { ...prevResponses };
        if (!updatedResponses[data.followCompositeKey]) {
          updatedResponses[data.followCompositeKey] = [];
        }
        const lastResponseIndex =
          updatedResponses[data.followCompositeKey].length - 1;
        if (lastResponseIndex >= 0) {
          updatedResponses[data.followCompositeKey][lastResponseIndex].bot +=
            data.bot;
        }
        return updatedResponses;
      });
    });

    socket.on('disconnect', () => {
      console.log('Socket Disconnected');
    });

    // socket.on("followup-query-user", (data) => {
    //   setFollowupResponses((prevResponses) => {
    //     const updatedResponses = { ...prevResponses };
    //     if (!updatedResponses[data.followCompositeKey]) {
    //       updatedResponses[data.followCompositeKey] = { user: "", bot: "" };
    //     }
    //     updatedResponses[data.followCompositeKey].user = data.user;
    //     return updatedResponses;
    //   });
    // });

    // socket.on("followup-query-bot", (data) => {
    //   setFollowupResponses((prevResponses) => {
    //     const updatedResponses = { ...prevResponses };
    //     if (!updatedResponses[data.followCompositeKey]) {
    //       updatedResponses[data.followCompositeKey] = { user: "", bot: "" };
    //     }
    //     updatedResponses[data.followCompositeKey].bot += data.bot;
    //     return updatedResponses;
    //   });
    // });

    return () => {
      socket.off("login-success");
      socket.off("query-response-solution");
      socket.off("image-url");
      socket.off("question-text");
      socket.off("query-update-solution");
      socket.off("final-solution");
      socket.off("common-question-solution");
      socket.off("followup-query-user");
      socket.off("followup-query-bot");
    };
  }, [stopRendering]);

  // useEffect(() => {
  //   checkForCamera();
  // }, []);

  console.log("React update started");

  const isAndroidWebView = () => {
    const isAndroidWebView = !!(window.AndroidWebView || navigator.userAgent.includes('wv'));
    return isAndroidWebView
  }

  const sendDataToAndroid = () => {
    const data = "doubtSolve"
    if (window.AndroidNative) {
      window.AndroidNative.sendDataToNative(data);
    }
  };

  const sendDataToFlutter = () => {
    const data = "doubtSolve";
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview.callHandler('sendDataToFlutter', data);
    }
  };




  function isDesktop() {
    return window.matchMedia("(min-width: 1024px)").matches;
  }

  const isIOSWebView = () => {
    const isIOSWebView = !!(window.webkit && window.webkit.messageHandlers);
    return isIOSWebView;
  }
  const isWebView = () => {
    return isAndroidWebView() || isIOSWebView();
  }


  const checkForCamera = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        if (videoDevices.length > 0) {
          setHasCamera(true);
          checkCameraPermissions();
        } else {
          setHasCamera(false);
          setAlertMessage("No camera available on this device.");
        }
      });
    } else {
      console.error("MediaDevices API is not supported in this browser.");
      setHasCamera(false);
      setAlertMessage("MediaDevices API is not supported in this browser.");
    }
  };

  const checkCameraPermissions = () => {
    if (useOld) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          setPermissionDenied(false);
          stream.getTracks().forEach((track) => track.stop());
        })
        .catch((err) => {
          if (
            err.name === "NotAllowedError" ||
            err.name === "PermissionDeniedError"
          ) {
            setPermissionDenied(true);
            setAlertMessage("Camera access denied. Please allow camera access.");
          } else {
            console.error("Error accessing the camera:", err);
            // setAlertMessage("Error accessing the camera.");
          }
        });
    } else {
      startup();
    }
  };

  const isRotationRequired = () => {
    if (window.mode === "landscape") {
      return true;
    }

    return false;
  }

  const rotateCanvasImage = (canvas) => {

    var ctx = canvas.getContext("2d");

    // Save the current canvas as an image (offscreen canvas approach)
    const offscreenCanvas = document.createElement('canvas');
    const offscreenCtx = offscreenCanvas.getContext('2d');

    // Set the size of the offscreen canvas to the current canvas dimensions
    offscreenCanvas.width = canvas.width;
    offscreenCanvas.height = canvas.height;

    // Copy the image from the original canvas to the offscreen canvas
    offscreenCtx.drawImage(canvas, 0, 0);

    // Clear the original canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    var originalCanvasWidth = canvas.width;
    var originalCanvasHeight = canvas.height;

    // Adjust the canvas dimensions for the rotated image
    canvas.width = originalCanvasHeight; // New width after rotating (previous height)
    canvas.height = originalCanvasWidth; // New height after rotating (previous width)

    // Move the canvas origin to the center and rotate 90 degrees
    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate(-(Math.PI / 2)); // 90 degrees

    // Draw the image back from the offscreen canvas onto the rotated context
    ctx.drawImage(offscreenCanvas, -offscreenCanvas.width / 2, -offscreenCanvas.height / 2);

    // Reset transformations so further drawings are not affected
    ctx.resetTransform();
  }

  const onTakePicture = (ev) => {
    //ev.preventDefault();

    var video = null;
    var canvas = null;
    var photo = null;
    video = document.getElementById("video");
    canvas = document.getElementById("canvas");
    photo = document.getElementById("photo");


    // Get the video dimensions and aspect ratio
    var videoWidth = video.videoWidth;
    var videoHeight = video.videoHeight;
    var videoAspectRatio = videoWidth / videoHeight;

    // Calculate the canvas size dynamically to remove extra white space
    if (canvas.width / canvas.height > videoAspectRatio) {
      // Scale by height, adjust width
      var scaledHeight = canvas.height;
      var scaledWidth = scaledHeight * videoAspectRatio;
    } else {
      // Scale by width, adjust height
      var scaledWidth = canvas.width;
      var scaledHeight = scaledWidth / videoAspectRatio;
    }

    setOriginalCanvas();
  }


  function setOriginalCanvas() {

    var video = null;
    var canvas = null;
    var photo = null;
    video = document.getElementById("video");
    canvas = document.getElementById("canvas");
    photo = document.getElementById("photo");

    var videoWidth = video.videoWidth;
    var videoHeight = video.videoHeight;
    var videoAspectRatio = videoWidth / videoHeight;

    var canvasWidth = videoWidth;
    var canvasHeight = canvasWidth / videoAspectRatio;

    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    // Draw the video frame on the canvas

    var context = canvas.getContext("2d");
    // Clear the canvas
    context.clearRect(0, 0, canvas.width, canvas.height);
    // Draw the video on the resized canvas
    context.drawImage(video, 0, 0, canvasWidth, canvasHeight);

    // Apply image quality enhancement (optional: brightness and contrast)
    context.filter = "brightness(1.1) contrast(1.2)";

    if (isRotationRequired()) {
      rotateCanvasImage(canvas)
    }

    var data = canvas.toDataURL("image/png", 1.0);
    //document.getElementById("canvas").style.display = "none";

    document.getElementById("camera").style.display = "none";
    document.getElementById("mainDiv").style['margin-top'] = "2%";
    setImage(data)
  }


  const startup = () => {

    document.getElementById("mainDiv").style['margin-top'] = "0px";
    document.getElementById("camera").style.display = "block";

    var video = null;
    var canvas = null;
    var photo = null;
    video = document.getElementById("video");
    canvas = document.getElementById("canvas");
    photo = document.getElementById("photo");

    navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: "environment",
          width: window.innerHeight,
          height: window.innerWidth
        },
        audio: false,
      })
      .then(function (stream) {
        setPermissionDenied(false);
        video.srcObject = stream;
        video.play();
        // setTimeout(() => {
        //   onTakePicture();
        // }, 8000);
      })
      .catch((err) => {
        if (
          err.name === "NotAllowedError" ||
          err.name === "PermissionDeniedError"
        ) {
          setPermissionDenied(true);
          setAlertMessage("Camera access denied. Please allow camera access.");
        } else {
          console.error("Error accessing the camera:", err);
          // setAlertMessage("Error accessing the camera.");
        }
      });

    video.addEventListener(
      "canplay",
      function (ev) {
        //if (!streaming) {
        // Use window dimensions to set canvas size initially

        //var width = window.innerWidth;
        //var height = window.innerHeight;

        video.style['padding-top'] = ((window.innerHeight - video.offsetHeight) / 2) + "px";

        // Set video and canvas dimensions
        // video.setAttribute("width", width);
        // video.setAttribute("height", height);
        // canvas.setAttribute("width", width);
        // canvas.setAttribute("height", height);

        // photo.setAttribute("width", width);
        // photo.setAttribute("height", height);

        // streaming = true;
        //}
      },
      false
    );
  }

  useEffect(() => {
    if (alertMessage) {
      const timer = setTimeout(() => {
        setAlertMessage("");
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [alertMessage]);

  // useEffect(() => {
  //   if (noCamMsg) {
  //     const timer = setTimeout(() => {
  //       setNoCamMsg(false);
  //     }, 5000);
  //     return () => clearTimeout(timer);
  //   }
  // }, [noCamMsg]);

  // const solutionBodyRef = useRef(null);
  // const scrollToBottom = () => {
  //   if (solutionBodyRef.current) {
  //     // solutionBodyRef.current.scrollTop = solutionBodyRef.current.scrollHeight;
  //     solutionBodyRef.current.scrollTo({
  //       top: solutionBodyRef.current.scrollHeight,
  //       behavior: "smooth",
  //     });
  //   }
  // };

  // useEffect(() => {
  //   scrollToBottom();
  // }, [queries]);

  const handleFileUpload = useCallback((source) => {
    setTextQuestion("");
    setShowCropArea(false);
    setFeedbackShow(false);
    if (image || textQuestion) {
      setQueries((prevQueries) => [
        ...prevQueries,
        { file: "", question: "", solution: "" },
      ]);
      if (image) {
        if (cropperRef.current?.cropper) {
          const cropWidth = window.innerHeight;
          const cropHeight = window.innerWidth;

          const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas({
            width: cropWidth,
            height: cropHeight,
            imageSmoothingEnabled: true,
            imageSmoothingQuality: 'high'
          });

          croppedCanvas.toBlob((blob) => {
            if (blob) {
              const file = new File([blob], orgFileName, {
                type: "image/png",
              });
              const fileContent = file;
              handleSocketEmit("image-query", {
                fileContent: fileContent,
                fileName: orgFileName,
              });
            }
          }, "image/png", 1);
        }

        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } else if (textQuestion) {
        setLoading(true);
        handleSocketEmit("text-query", { textQuestion: textQuestion });
      } else {
        alert("Please select a question first.");
      }
      setProcessing("processing");
      setLoading(true);
    }
    if (source === 'Submit Button') {
      trackEvent(submit_image);
    } else if (source === 'Icon Button') {
      trackEvent(SendButton);
    }
  }, [image, textQuestion]);

  const onChange = (e) => {
    e.preventDefault();
    // cancelButtonRef.current.click();
    let file;
    if (e.dataTransfer) {
      file = e.dataTransfer.files[0];
    } else if (e.target) {
      file = e.target.files[0];
    }
    if (file) {
      setOrgFileName(file.name);
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        setShowCropArea(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImage(imageUrl);
      setOrgFileName(`db_chatbot_${Date.now()}.jpg`);
      return () => URL.revokeObjectURL(imageUrl);
    }
  };

  const handleKeyPress = (e) => {
    // debugger
    if (e.key === "Enter") {
      e.preventDefault();
      handleFileUpload(null);
    }
    trackEvent(type_doubts)
  };

  const handleFollowupKeyPress = (e, line, compositeKey) => {
    if (e.key === "Enter") {
      e.preventDefault();
      followUpInputQuestions(line, compositeKey);
    }
  };

  const toggleStepVisibility = (questionIndex, stepIndex) => {
    const compositeKey = `${questionIndex}-${stepIndex}`;
    setVisibleSteps((prevState) => ({
      ...prevState,
      [compositeKey]: !prevState[compositeKey],
    }));
    setTimeout(() => {
      if (explanationRefs.current[compositeKey]) {
        explanationRefs.current[compositeKey].scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  };

  const allClear = () => {
    setQueries([]);
    setCommonQuestionSolutions({});
    setFollowupResponses({});
    setFollowupInputQuestion({});
    setVisibleSteps({});
    setProcessing("");
    setFeedbackShow(true);
    setFeedbackDataShow(false)
    setThanksFeedBack(false);

  };

  const stopRenderingHandler = () => {
    setStopRendering(true);
    setLoading(false);
  };

  const handleCameraInput = () => {

    // setCameraOpened(true);
    trackEvent(click_photo);
    setShowCropArea(true);
    // cancelButtonRef.current.click();
    if (!isAndroidWebView()) {
      document.getElementById('cameraInput').click()
    } else {
      checkForCamera();
    }
  };

  const cancelupload = (source) => {
    if (source === 'cancel attach') {
      trackEvent(cancel_attach);
    }
  };
  const closeModal = () => {
    setImage(null);
    setOrgFileName("");
    setShowCropArea(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    trackEvent(cancel_image);
  };

  const commonQuestions = (line, questionIndex, stepIndex) => {
    const compositeKey = `${questionIndex}-${stepIndex}`;
    setSelectedLine(line);
    handleSocketEmit("common-question-query", {
      commonQuestion: line,
      indexValue: compositeKey,
    });
    // console.log(line, compositeKey);
  };

  const handleFollowUpClick = (line, question, compositeKey) => {
    setFollowupQuestion(question);
    followUpQuestions(line, compositeKey, question); // Pass question directly
  };

  const followUpQuestions = (line, compositeKey, question) => {
    const followCompositeKey = compositeKey;
    // const question = question;
    // setFollowupResponses((prevResponses) => {
    //   const updatedResponses = { ...prevResponses };
    //   if (!updatedResponses[followCompositeKey]) {
    //     updatedResponses[followCompositeKey] = { user: "", bot: "" };
    //   }
    //   updatedResponses[followCompositeKey].bot = "";
    //   return updatedResponses;
    // });
    handleSocketEmit("followup-query", {
      line: line,
      question: question,
      followCompositeKey: followCompositeKey,
    });
    // console.log(line, question, followCompositeKey);
  };

  const followUpInputQuestions = (line, compositeKey) => {
    const followCompositeKey = compositeKey;
    const question = followupInputQuestion[compositeKey];

    // setFollowupResponses((prevResponses) => {
    //   const updatedResponses = { ...prevResponses };
    //   if (!updatedResponses[followCompositeKey]) {
    //     updatedResponses[followCompositeKey] = { user: "", bot: "" };
    //   }
    //   updatedResponses[followCompositeKey].bot = "";
    //   return updatedResponses;
    // });
    handleSocketEmit("followup-query", {
      line: line,
      question: question,
      followCompositeKey: followCompositeKey,
    });
    setFollowupInputQuestion((prev) => ({
      ...prev,
      [compositeKey]: "",
    }));
    // console.log(line, followupInputQuestion[compositeKey], followCompositeKey);
  };

  const maxLengthFollowup = 250;
  const handleFollowupInputChange = (e, compositeKey) => {
    const { value } = e.target;
    if (value.length < maxLengthFollowup) {
      setFollowupInputQuestion((prev) => ({
        ...prev,
        [compositeKey]: value,
      }));
      setFollowUpData(value)
    } else if (value.length >= maxLengthFollowup) {
      toast.warning(
        `maximum character limit ${followupInputQuestion[compositeKey].length}/${maxLengthFollowup}`
      );
    }
  };

  const shouldShowFollowUpButton = (line) => {
    return [
      "Step 1:",
      "Step 2:",
      "Step 3:",
      "Step 4:",
      "Step 5:",
      "Step 6:",
      "Step 7:",
      "Step 8:",
      "Step 9:",
      "Step 10:",
    ].some((step) => line.startsWith(`${step}`));
  };

  const preprocessText = (text) => {
    return text.replace(/\*/g, "");
  };

  const extractStep = (text) => {
    const stepRegex = /Step\s+(\d+):/;
    const match = text.match(stepRegex);
    return match ? match[1] : null;
  };
  const maxLength = 500;
  const handleChange = (e) => {
    const { value } = e.target;
    if (value.length < maxLength) {
      setTextQuestion(value);
    } else if (value.length >= maxLength) {
      toast.warning(`maximum character limit ${textQuestion.length}/${maxLength}`);
    }
  };

  // const permissionCamera = () => {


  //  navigator.permissions.query({name: "microphone"})
  //  .then(({state}) => { console.log(state); })
  // }

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      if (hasCamera === true) {
        setHasCamera(false);

      }
      // permissionCamera()
    };
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', handleBackButton);
    // checkForCamera()

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [hasCamera]);

  const renderObjectData = [
    {
      id: 1,
      imageData: "/microscope.png",
      textData: "Determine focal length of a convex length"
    },
    {
      id: 2,
      imageData: "/glass.png",
      textData: "Explain Cannizzaro reaction in details"
    },
    {
      id: 3,
      imageData: "/math.png",
      textData: "How to find the slope of a curve?"
    },
    {
      id: 4,
      imageData: "/DNA.png",
      textData: "Differentiate between mitosis and meiosis?"
    }
  ]

  const handleClickRenderObject = (data, id) => {
    setQueries((prevQueries) => [
      ...prevQueries,
      { file: "", question: "", solution: "" },
    ]);
    console.log("chec");

    // Ensure id is treated as a number
    const numericId = Number(id + 1);
    handleSocketEmit("text-query", { textQuestion: data.textData });
    setProcessing("processing");
    setLoading(true);
    setFeedbackShow(false)
    if (numericId === 1) {
      trackEvent(Suggestion_1st);
    } else if (numericId === 2) {
      trackEvent(Suggestion_2nd);
    } else if (numericId >= 3) {
      trackEvent(other_Suggestion);
    }
  }

  const handleClickRenderObject2 = (data, id) => {
    setQueries((prevQueries) => [
      ...prevQueries,
      { file: "", question: "", solution: "" },
    ]);
    console.log("chec");

    // Ensure id is treated as a number
    const numericId = Number(id + 1);
    handleSocketEmit("text-query", { textQuestion: data.question });
    setProcessing("processing");
    setLoading(true);
    setFeedbackShow(false)
    if (numericId === 1) {
      trackEvent(Suggestion_1st);
    } else if (numericId === 2) {
      trackEvent(Suggestion_2nd);
    } else if (numericId >= 3) {
      trackEvent(other_Suggestion);
    }
  }



  const handleFeedback = (data) => {
    setThanksFeedBack(true);
    if (doubtId) {
      handleSocketEmit("student-feedback", {
        doubtId,
        feedback: data
      });
    }
    if (data === 'Yes') {
      trackEvent(yes_feedback);
    } else if (data === 'No') {
      trackEvent(no_feedback);
    }
  }

  const handleAskAnotherDoubt = () => {
    setQueries([]);
    setCommonQuestionSolutions({});
    setFollowupResponses({});
    setFollowupInputQuestion({});
    setVisibleSteps({});
    setProcessing("");
    setFeedbackShow(true);
    setFeedbackDataShow(false)
    setThanksFeedBack(false);
    trackEvent(ask_another_doubt)
  }

  return (
    <div
      style={{ overflowY: "auto", maxHeight: "96vh" }}
    // ref={solutionBodyRef}
    >
      <Turnstile ref={cfTurnstileRef} onWidgetLoad={() => {
        handleSocketInit();
      }} siteKey="0x4AAAAAABCWVGqmBlM2YCzw" onError={(error) => {
        setIsValidUser(false)
      }
      } />
      {
        !isValidUser ? <BotDetected /> : null
      }
      {
        (isValidUser && notLogin) ? <Loader /> : null
      }
      {(returningApiKey && isValidUser) ? (
        <div id="mainDiv" className="mainDiv">

          <>
            <div className="camera" id="camera" style={{ display: 'none' }}>
              <video id="video" autoPlay muted playsInline preload="auto" poster="/ps.png">Video stream not available.</video>
              <div className="cameraDiv">
                <button
                  className="btn btn-primary btnFont cameraBtn"
                  style={{ zIndex: "99999" }}
                  onClick={() => {
                    onTakePicture()
                    // setImage(cameraRef.current.takePhoto());
                    setOrgFileName(`db_chatbot_${Date.now()}.jpg`);
                  }}
                >
                  <i className="bi bi-camera"></i>
                </button>
              </div>
            </div>
            <canvas id="canvas" style={{ display: 'none' }}> </canvas>
            <img id="photo" style={{ display: 'none', objectFit: 'cover' }} alt="" />
          </>

          <header className="header" style={{ display: "none" }}>
            <h2>DB-BOAT</h2>
            <div className="userLogin">R</div>
          </header>
          <div className="mainDivTwo mx-auto">
            {processing ? (
              <div>
                <div className="imageFormate">
                  {queries.map((query, questionIndex) => (
                    <div key={questionIndex} className="queryBlock">
                      {query.imageUrl ? (
                        <div className="imageDiv">
                          <img
                            src={query.imageUrl}
                            alt=""
                            style={{ width: "100%" }}
                          />
                        </div>
                      ) : null}
                      {query.question ? (
                        <div className="questionDiv">
                          <h6 className="mb-0">
                            <MathJax.Context input="tex" options={mathJaxConfig}>
                              <div className="d-flex">
                                <span className="qDiv">Q.</span>
                                <div style={{ wordSpacing: '3px' }}>
                                  {/* <MathJax.Text text={query?.question && query?.question} /> */}
                                  <SafeMathJaxText content={query?.question} />
                                </div>
                              </div>
                            </MathJax.Context>
                          </h6>
                        </div>
                      ) : null}
                      {query.solution ? (
                        <div>
                          <h4
                            style={{ color: "#2454DD" }}
                            className="solutionNameHead mb-0"
                          >
                            Solution
                          </h4>
                          <MathJax.Context input="tex" options={mathJaxConfig}>
                            <div>
                              {/* {splitBySteps(query.solution).map((line, stepIndex)  */}
                              {query.solution
                                .split(/### |#### /)
                                .map((line) => line.trim())
                                .filter((line) => line !== "")
                                .map((line, stepIndex) => {
                                  const compositeKey = `${questionIndex}-${stepIndex}`;
                                  const preprocessedLine = preprocessText(line);
                                  const stepNumber = extractStep(line);

                                  return (
                                    <div key={stepIndex}>
                                      <div className="solutionDiv">
                                        <pre
                                          style={{
                                            whiteSpace: "pre-wrap",
                                            wordWrap: "break-word",
                                            color: "#333333",
                                            // color: line.includes("Step") ? "#2454DD" : "#333333",
                                          }}
                                        >
                                          <div
                                            ref={(el) => {

                                              explanationRefs.current[compositeKey] = el

                                            }}
                                          >
                                            {shouldShowFollowUpButton(
                                              preprocessedLine
                                            ) && (
                                                <div className="d-flex align-items-center justify-content-between mb-1">
                                                  <div>
                                                    <h6
                                                      style={{ color: "#2454DD" }}
                                                    >
                                                      Step {stepNumber && stepNumber}:
                                                    </h6>
                                                  </div>
                                                  <button
                                                    className="followupButton"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Suggestions"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      toggleStepVisibility(
                                                        questionIndex,
                                                        stepIndex
                                                      );
                                                      commonQuestions(
                                                        preprocessedLine,
                                                        questionIndex,
                                                        stepIndex
                                                      );

                                                      trackEvent(explain_step);

                                                    }}
                                                  >
                                                    {/* <img
                                                    src="./followup.svg"
                                                    alt=""
                                                  /> */}
                                                    Explain
                                                  </button>
                                                </div>
                                              )}
                                          </div>
                                          {/* <MathJax.Text
                                            text={preprocessedLine}
                                          /> */}
                                          <SafeMathJaxText content={preprocessedLine} />
                                        </pre>
                                        <div
                                          className="stepAskDiv"
                                          style={{
                                            display: visibleSteps[compositeKey]
                                              ? "block"
                                              : "none",
                                          }}
                                        >
                                          <div

                                            className="talkDiv">
                                            {/* <button className="btn autoSuggestion">
                                              <p>Ask a question</p>
                                              <i
                                                className="bi bi-person-raised-hand"
                                                style={{ color: "#7D8592" }}
                                              ></i>
                                            </button>
                                            <button className="btn autoSuggestion">
                                              <p>Help. I'm lost</p>
                                              <i
                                                className="bi bi-emoji-tear"
                                                style={{ color: "#7D8592" }}
                                              ></i>
                                            </button> */}
                                            {/* <div> */}
                                            <button
                                              className="btn autoSuggestion"
                                              onClick={() => {
                                                // e.preventDefault();
                                                // setFollowupQuestion(
                                                //   "Ask a question"
                                                // );
                                                handleFollowUpClick(
                                                  line,
                                                  "Ask a question",
                                                  compositeKey
                                                );
                                                trackEvent(followup_question)
                                              }}
                                            >
                                              <p>Ask a question</p>
                                              <i
                                                className="bi bi-person-raised-hand"
                                                style={{
                                                  color: "#7D8592",
                                                }}
                                              ></i>
                                            </button>
                                            <button
                                              className="btn autoSuggestion"
                                              onClick={() => {
                                                // e.preventDefault();
                                                // setFollowupQuestion(
                                                //   "Help. I'm lost"
                                                // );
                                                handleFollowUpClick(
                                                  line,
                                                  "Help. I'm lost",
                                                  compositeKey
                                                );
                                                trackEvent(followup_question)
                                              }}
                                            >
                                              <p>Help. I'm lost</p>
                                              <i
                                                className="bi bi-emoji-tear"
                                                style={{
                                                  color: "#7D8592",
                                                }}
                                              ></i>
                                            </button>
                                            {/* </div> */}
                                            <div>
                                              {commonQuestionSolutions[
                                                compositeKey
                                              ] ? (
                                                <div>
                                                  {commonQuestionSolutions[
                                                    compositeKey
                                                  ].map((question, qIndex) => (
                                                    <button
                                                      className="btn autoSuggestion"
                                                      onClick={() => {
                                                        // e.preventDefault();
                                                        // setFollowupQuestion(
                                                        //   question
                                                        // );
                                                        handleFollowUpClick(
                                                          line,
                                                          question,
                                                          compositeKey
                                                        );
                                                        trackEvent(followup_question)
                                                      }}
                                                    >
                                                      <pre
                                                        key={qIndex}
                                                        style={{
                                                          whiteSpace:
                                                            "pre-wrap",
                                                          wordWrap:
                                                            "break-word",
                                                          color: "#7D8592",
                                                        }}
                                                      >
                                                        <MathJax.Text
                                                          text={question}
                                                        />
                                                      </pre>
                                                      <i
                                                        className="bi bi-question-circle"
                                                        style={{
                                                          color: "#7D8592",
                                                        }}
                                                      ></i>
                                                    </button>
                                                  ))}
                                                  <div>
                                                    {followupResponses[
                                                      compositeKey
                                                    ] ? (
                                                      <div>
                                                        {followupResponses[
                                                          compositeKey
                                                        ].map(
                                                          (response, index) => (
                                                            <div key={index}>
                                                              <div className="d-flex justify-content-end">
                                                                <div className="askQuestion">
                                                                  <div>
                                                                    <h6
                                                                      style={{
                                                                        color:
                                                                          "#2454DD",
                                                                      }}
                                                                    >
                                                                      You
                                                                    </h6>
                                                                  </div>
                                                                  <div>
                                                                    <pre
                                                                      style={{
                                                                        whiteSpace:
                                                                          "pre-wrap",
                                                                        wordWrap:
                                                                          "break-word",
                                                                        color:
                                                                          "#7D8592",
                                                                      }}
                                                                    >
                                                                      {/* <MathJax.Text
                                                                        text={
                                                                          response.user
                                                                        }
                                                                      /> */}
                                                                      <SafeMathJaxText content={response.user} />
                                                                    </pre>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="d-flex justify-content-start">
                                                                <div className="aiSolution">
                                                                  <h6
                                                                    style={{
                                                                      color:
                                                                        "#2454DD",
                                                                    }}
                                                                  >
                                                                    AI
                                                                  </h6>
                                                                  <div>
                                                                    <pre
                                                                      style={{
                                                                        whiteSpace:
                                                                          "pre-wrap",
                                                                        wordWrap:
                                                                          "break-word",
                                                                        color:
                                                                          "#7D8592",
                                                                      }}
                                                                    >
                                                                      {/* <MathJax.Text
                                                                        text={
                                                                          preprocessText(response.bot)
                                                                          // response.bot
                                                                        }
                                                                      /> */}
                                                                      <SafeMathJaxText content={response.bot} />
                                                                    </pre>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    ) : null}
                                                    {/* {followupResponses[
                                                      compositeKey
                                                    ] ? (
                                                      <div>
                                                        <div className="d-flex justify-content-end">
                                                          <div className="askQuestion">
                                                            <div>
                                                              <h6
                                                                style={{
                                                                  color:
                                                                    "#2454DD",
                                                                }}
                                                              >
                                                                You
                                                              </h6>
                                                            </div>
                                                            <div>
                                                              <pre
                                                                style={{
                                                                  whiteSpace:
                                                                    "pre-wrap",
                                                                  wordWrap:
                                                                    "break-word",
                                                                  color:
                                                                    "#7D8592",
                                                                }}
                                                              >
                                                                <MathJax.Text
                                                                  text={
                                                                    followupResponses[
                                                                      compositeKey
                                                                    ].user
                                                                  }
                                                                />
                                                              </pre>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="d-flex justify-content-start">
                                                          <div className="aiSolution">
                                                            <h6
                                                              style={{
                                                                color:
                                                                  "#2454DD",
                                                              }}
                                                            >
                                                              AI
                                                            </h6>
                                                            <div>
                                                              <pre
                                                                style={{
                                                                  whiteSpace:
                                                                    "pre-wrap",
                                                                  wordWrap:
                                                                    "break-word",
                                                                  color:
                                                                    "#7D8592",
                                                                }}
                                                              >
                                                                <MathJax.Text
                                                                  text={
                                                                    followupResponses[
                                                                      compositeKey
                                                                    ].bot
                                                                  }
                                                                />
                                                              </pre>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : null} */}
                                                  </div>
                                                </div>
                                              ) : null}
                                            </div>
                                            <div className="followupInputDiv d-flex align-items-center w-100">
                                              <input
                                                type="text"
                                                className="followupInput"
                                                value={
                                                  followupInputQuestion[
                                                  compositeKey
                                                  ] || ""
                                                }
                                                onChange={(e) =>
                                                  handleFollowupInputChange(
                                                    e,
                                                    compositeKey
                                                  )
                                                }
                                                onKeyPress={(e) =>
                                                  handleFollowupKeyPress(
                                                    e,
                                                    line,
                                                    compositeKey
                                                  )
                                                }
                                                placeholder="Enter your doubt here"
                                                maxLength={maxLengthFollowup}
                                              />
                                              <button
                                                className="btn btn-sm"
                                                onClick={() =>
                                                  followUpInputQuestions(
                                                    line,
                                                    compositeKey
                                                  )
                                                }
                                              >
                                                {" "}
                                                {/* <img
                                                  className="frontIcons"
                                                  src="/send.png"
                                                  alt=""
                                                /> */}
                                                {
                                                  followUpData && followUpData.length > 0 ? (
                                                    <SendIcon sx={{ color: '#2454dd', fontSize: 32 }} />

                                                  ) : (
                                                    <>
                                                      <img className="frontIcons" src="/send.png" alt="" />
                                                    </>
                                                  )
                                                }
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </MathJax.Context>
                        </div>
                      ) : null}
                      {!query.question && query.solution ? (
                        <div className="noQuestionFound mb-4" >
                          {/* <button
                            style={{ backgroundColor: partnerData.primaryColor ? partnerData.primaryColor : '#2454dd', color: 'white', borderColor: 'white', marginTop: "40px" }}

                            className="Ask_Another_Doubt"
                            onClick={handleAskAnotherDoubt}
                          >
                            Ask another Doubt
                          </button> */}
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>

                {processing && loading ? (
                  <div className="d-flex align-items-center processing">
                    <h6>{processing}</h6>
                    <div
                      className="spinner-grow text-primary loader1"
                      role="status"
                    ></div>
                    <div
                      className="spinner-grow text-secondary loader2"
                      role="status"
                    ></div>
                    <div
                      className="spinner-grow text-success loader3"
                      role="status"
                    ></div>
                  </div>
                ) : null}
              </div>
            ) : (
              <>
                {showModal && (
                  partnerData.hasOwnProperty("popup") ? (
                    partnerData.popup ? (
                      <FreeFinish2
                        showModal={showModal}
                        setShowModal={setShowModal}
                        FreeDoubt={FreeDoubt}
                        codeSnippet={codeSnippet}
                        freeFinishProps={freeFinishProps}
                      />
                    ) : (
                      <FreeFinish
                        showModal={showModal}
                        setShowModal={setShowModal}
                        FreeDoubt={FreeDoubt}
                        codeSnippet={codeSnippet}
                        freeFinishProps={freeFinishProps}
                      />
                    )
                  ) : <FreeFinish
                    showModal={showModal}
                    setShowModal={setShowModal}
                    FreeDoubt={FreeDoubt}
                    codeSnippet={codeSnippet}
                    freeFinishProps={freeFinishProps}
                  />
                )}


                <div className="frontScreen" style={{ display: showModal === true ? "none" : "block" }} >
                  {/* <div className="mx-auto"> */}
                  {/* <div className="cc d-flex justify-content-center"> */}
                  <div className="frontScreenBox">
                    <div className="dbLogoDiv">
                      <img
                        src={partnerData.doubtIcon
                          ? partnerData.doubtIcon
                          : "/logoFrame.png"
                        }
                        alt="plan image"
                        style={{
                          width: "70px",
                          height: "70px",
                          borderRadius: "50px",
                          marginBottom: "12px",
                        }}
                      />
                    </div>
                    <div>
                      <h6
                        style={{
                          // fontFamily: 'Open Sauce One',
                          fontSize: "14px",
                          fontWeight: 600,
                          lineHeight: "16px",
                          textAlign: "center",
                          color: "#000000",
                        }}
                      >
                        {partnerData.blankTitle ? partnerData.blankTitle : "Struggling with a Question?"}

                      </h6>
                      <p
                        style={{
                          // fontFamily: 'Open Sauce One',
                          fontSize: "12px",
                          fontWeight: 400,
                          lineHeight: "16px",
                          textAlign: "center",
                          color: "#000000",
                        }}
                      >
                        {partnerData.blankSubtitle ? partnerData.blankSubtitle : "Upload Your Screenshot and Get a Quick Solution!"}


                      </p>
                    </div>
                    {partnerData.showQuestionImages && (
                      <div
                        className="frontScreenBox"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                          textAlign: "start",
                          gap: "25px",
                          backgroundColor: "#FFFFFF",
                        }}
                      >
                        {partnerData.Questions && partnerData.Questions.length > 0 ? (
                          partnerData.Questions.map((item, id) => (
                            <div
                              key={item._id}
                              className="card"
                              style={{
                                display: "flex",
                                flexDirection: 'column',
                                backgroundColor: "#FFFFFF",
                                padding: "12px",
                                borderRadius: "12px",
                                gap: "8px",
                              }}
                              onClick={() => {
                                handleClickRenderObject2(item, id);
                              }}
                            >
                              <img
                                src={item.image}
                                // alt="question_image"
                                style={{ height: "30px", width: "30px" }}
                              />
                              <p
                                style={{
                                  fontSize: "12px",
                                  fontWeight: 400,
                                  lineHeight: "16px",
                                  color: "#000000",
                                }}
                              >
                                {item.question}
                              </p>
                            </div>
                          ))
                        ) : (
                          renderObjectData.map((item) => (
                            <div
                              key={item.id}
                              className="card"
                              style={{
                                display: "flex",
                                flexDirection: 'column',
                                backgroundColor: "#FFFFFF",
                                padding: "12px",
                                borderRadius: "12px",
                                gap: "8px",
                              }}
                              onClick={() => {
                                handleClickRenderObject(item, item.id);
                              }}
                            >
                              <img
                                src={item.imageData}
                                alt="question_image"
                                style={{ height: "30px", width: "30px" }}
                              />
                              <p
                                style={{
                                  fontSize: "12px",
                                  fontWeight: 400,
                                  lineHeight: "16px",
                                  color: "#000000",
                                }}
                              >
                                {item.textData}
                              </p>
                            </div>
                          ))
                        )}


                      </div>
                    )}
                  </div>
                  {/* </div> */}
                  {/* </div> */}
                </div>
              </>
            )}
          </div>
          {showCropArea && (
            <div className="d-flex align-items-center justify-content-center mx-auto flex-column">
              {image ? (
                <div className="cropDiv">
                  <div
                    style={{
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                      overflow: "scroll",
                    }}
                  >
                    <Cropper
                      ref={cropperRef}
                      style={{ width: "100%", maxHeight: "70vh" }}
                      zoomTo={0}
                      initialAspectRatio={1}
                      preview=".img-preview"
                      src={image}
                      viewMode={1}
                      minCropBoxHeight={10}
                      minCropBoxWidth={10}
                      background={false}
                      responsive={true}
                      autoCropArea={1}
                      checkOrientation={false}
                      dragMode="none"
                      guides={true}
                      touchDragZoom={true}
                      zoomable={true}
                      scalable={true}
                      rotatable={true}
                    />
                  </div>
                  <div className="cropAreaFooter">
                    <button
                      type="button"
                      className="btn btn-sm "
                      style={{ border: '1px solid black' }}
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-sm"
                      style={{ backgroundColor: partnerData.primaryColor ? partnerData.primaryColor : '#2454dd', color: '#ffff' }}
                      onClick={() => handleFileUpload('Submit Button')}

                    >
                      Submit
                    </button>

                  </div>
                </div>
              ) : (
                <div className="cameraBody">
                  {hasCamera ? (
                    <div className="cameraBody">
                      {!permissionDenied ? (
                        <div className="cameraOpen">
                          {
                            isAndroidWebView() ?

                              <>
                                {useOld ?
                                  <>
                                    <Camera
                                      ref={cameraRef}
                                      facingMode="environment"
                                      isImageMirror={false}
                                    />


                                    <div className="cameraDiv">
                                      <button
                                        className="btn btn-primary btnFont cameraBtn"
                                        onClick={() => {
                                          setImage(cameraRef.current.takePhoto());
                                          setOrgFileName(`db_chatbot_${Date.now()}.jpg`);
                                        }}
                                      >
                                        <i className="bi bi-camera"></i>
                                      </button>
                                    </div>
                                  </>
                                  : <>
                                  </>
                                }
                              </>
                              : <></>
                          }
                        </div>
                      ) : (
                        alertMessage && (
                          <div
                            className="noCamera alert alert-warning alert-dismissible fade show"
                            role="alert"
                          >
                            <p className="m-0 p-0">{alertMessage}</p>
                            <button
                              type="button"
                              className="btn btn-sm p-1"
                              data-bs-dismiss="alert"
                              aria-label="Close"
                              onClick={() => setAlertMessage("")}
                            >
                              <i className="bi bi-x-circle"></i>
                            </button>
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    alertMessage && (
                      <div
                        className="noCamera alert alert-warning alert-dismissible fade show"
                        role="alert"
                      >
                        <p className="m-0 p-0">{alertMessage}</p>
                        <button
                          type="button"
                          className="btn btn-sm p-1"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                          onClick={() => setAlertMessage("")}
                        >
                          <i className="bi bi-x-circle"></i>
                        </button>
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          )}
          {feedbackShow ? (
            <>
              <div className="bottomArea">
                <div className="selectFile">
                  {!isValidUser && <div className="invalidUser">
                    Invalid request, if you are seeing this error then contact support
                  </div>}
                  {isValidUser && <React.Fragment>
                    <input
                      type="file"
                      id="file-crop"
                      onChange={onChange}
                      ref={fileInputRef}
                      style={{ display: "none" }}
                    />
                    <textarea
                      rows="1"
                      className="inputArea textQuestion"
                      style={{ paddingLeft: "13px" }}
                      placeholder={
                        partnerData.placeholderText
                          ? partnerData.placeholderText
                          : "Enter a question here"
                      }
                      type="text"
                      value={textQuestion}
                      // onChange={(e) => setTextQuestion(e.target.value)}
                      onChange={handleChange}
                      onKeyPress={handleKeyPress}
                      maxLength={maxLength}
                    />
                    {partnerData.hasOwnProperty("showAttachments") ? (
                      partnerData.showAttachments ? (
                        <button
                          className="btn"
                          type="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasBottom"
                          aria-controls="offcanvasBottom"
                        >
                          <img
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Select Image"
                            className="frontIcons"
                            onClick={() => trackEvent(attach_icon)}
                            src="/attach.png"
                            alt=""
                          />
                        </button>
                      ) : null
                    ) : (
                      <button
                        className="btn"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasBottom"
                        aria-controls="offcanvasBottom"
                      >
                        <img
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Select Image"
                          className="frontIcons"
                          onClick={() => trackEvent(attach_icon)}
                          src="/attach.png"
                          alt=""
                        />
                      </button>
                    )}

                    <div
                      className="offcanvas offcanvas-bottom"
                      tabIndex="-1"
                      id="offcanvasBottom"
                      aria-labelledby="offcanvasBottomLabel"
                    >
                      <div className="offcanvas-body small">
                        <div className="popupContent">
                          <div className="d-flex align-items-center mb-3">
                            <button
                              id="cameraOpen"
                              onClick={handleCameraInput}
                              className="btn me-1"
                            >
                              <img className="popupIcon" src="/camera.png" alt="" />
                            </button>
                            <label htmlFor="cameraOpen" style={{ cursor: "pointer" }}>
                              <h6 className="mb-0" style={{ color: "#7D8592" }}>
                                Click a photo
                              </h6>
                            </label>
                            {
                              !isAndroidWebView() &&
                              <input ref={cameraRef} type="file" id="cameraInput" accept="image/*" onChange={handleFileChange} capture="camera" style={{ display: 'none' }} />
                            }
                          </div>

                          <div
                            className="d-flex align-items-center"
                            onClick={() => {
                              // cancelButtonRef.current.click()
                              trackEvent(upload_document)
                            }}
                          >
                            <label htmlFor="file-crop" className="btn me-1">
                              <img
                                className="popupIcon"
                                src="/file-text.png"
                                alt=""
                              />
                            </label>
                            <label htmlFor="file-crop" style={{ cursor: "pointer" }}>
                              <h6 className="mb-0" style={{ color: "#7D8592" }}>
                                Upload a document
                              </h6>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center">
                        <button
                          ref={cancelButtonRef}
                          type="button"
                          className="btn text-reset mb-4"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                          style={{ letterSpacing: "1.71px", color: "#DF4952" }}

                          onClick={() => cancelupload('cancel attach')}
                        >
                          CANCEL
                        </button>
                      </div>
                    </div>
                    {/* <div>
                <small>{`${textQuestion.length}/${maxLength}`}</small>
              </div> */}
                    {processing && loading ? (
                      <div className="renderButtonTwo">
                        <button onClick={stopRenderingHandler} className="btn">
                          <i className="bi bi-stop-circle btnFont"></i>
                        </button>
                      </div>
                    ) : (

                      <div className="renderButtonOne">
                        <button
                          onClick={() => handleFileUpload('Icon Button')}
                          className="btn"
                          style={{
                            borderRadius: '50%',
                            width: '48px',
                            height: '48px',
                            backgroundColor: partnerData.primaryColor ? partnerData.primaryColor : '#2454dd',
                            color: '#fff', marginLeft: '12px'
                          }}
                        >
                          {
                            textQuestion && textQuestion.length > 0 ? (
                              <SendIcon sx={{ color: 'white', fontSize: 32 }} />
                            ) : (
                              <>
                                <img
                                  className="frontIcons"
                                  src="/send.png"
                                  alt=""
                                  style={{ filter: 'brightness(0) invert(1)', width: '24px', height: '24px' }}
                                />

                              </>
                            )
                          }
                        </button>
                      </div>

                    )}
                  </React.Fragment>}
                </div>
              </div>
            </>
          ) : (
            <>
              {/* {feedbackDataShow && (
                
                <div className="bottomArea">
                  <div className="selectFile1">
                    {thanksFeedBack ? (
                      <div className="thankdFeedBackDiv">
                        <div className="FeedBackDiv">
                          🙏 Thank you for the feedback!
                        </div>
                        <button
                          className="Ask_Another_Doubt"
                          onClick={handleAskAnotherDoubt}
                        >
                          Ask another Doubt
                        </button>
                      </div>
                    ) : (
                      <div className="helpulDiv1">
                        <div className="helpulDiv">
                          <div>
                            <h5 className="was_helpful">Was this helpful?</h5>
                          </div>
                          <div className="feedBack-Button">
                            <button
                              className="green-button"
                              onClick={() => handleFeedback("Yes")}
                            >
                              <i className="bi bi-hand-thumbs-up icon-#71e271"></i>
                              Yes
                            </button>
                            <button
                              className="red-button"
                              onClick={() => handleFeedback("No")}
                            >
                              <i
                                className="bi bi-hand-thumbs-down icon-rgb(225, 61, 93)"
                                style={{ marginTop: "2px" }}
                              ></i>
                              No
                            </button>
                          </div>
                        </div>

                        <button
                          className="Ask_Another_Doubt"
                          onClick={handleAskAnotherDoubt}
                        >
                          Ask another Doubt
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )} */}




              <>
                {partnerData.hasOwnProperty("showFeedback") ? (
                  partnerData.showFeedback ? (
                    feedbackDataShow && (
                      <div className="bottomArea">
                        <div className="selectFile1">
                          {thanksFeedBack ? (
                            <div className="thankdFeedBackDiv">
                              <div className="FeedBackDiv">🙏 Thank you for the feedback!</div>
                              <button
                                style={{ backgroundColor: partnerData.primaryColor ? partnerData.primaryColor : '#2454dd', color: 'white', borderColor: 'white' }}
                                className="Ask_Another_Doubt"
                                onClick={handleAskAnotherDoubt}
                              >
                                Ask another Doubt
                              </button>
                            </div>
                          ) : (
                            <div className="helpulDiv1">
                              <div className="helpulDiv">
                                <div>
                                  <h5 className="was_helpful">Was this helpful?</h5>
                                </div>
                                <div className="feedBack-Button">
                                  <button
                                    className="green-button"
                                    onClick={() => handleFeedback("Yes")}
                                  >
                                    <i className="bi bi-hand-thumbs-up icon-#71e271"></i> Yes
                                  </button>
                                  <button
                                    className="red-button"
                                    onClick={() => handleFeedback("No")}
                                  >
                                    <i
                                      className="bi bi-hand-thumbs-down icon-rgb(225, 61, 93)"
                                      style={{ marginTop: "2px" }}
                                    ></i> No
                                  </button>
                                </div>
                              </div>
                              <button
                                style={{ backgroundColor: partnerData.primaryColor ? partnerData.primaryColor : '#2454dd', color: 'white', borderColor: 'white' }}
                                className="Ask_Another_Doubt"
                                onClick={handleAskAnotherDoubt}
                              >
                                Ask another Doubt
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  ) : partnerData.showFeedback === false && feedbackDataShow === true ?
                    <div className="bottomArea">
                      <div className="selectFile1">
                        <button
                          style={{ backgroundColor: partnerData.primaryColor ? partnerData.primaryColor : '#2454dd', color: 'white' }}
                          className="Ask_Another_Doubt"
                          onClick={handleAskAnotherDoubt}
                        >
                          Ask another Doubt
                        </button>
                      </div>
                    </div>
                    : null
                ) : (
                  feedbackDataShow && (
                    <div className="bottomArea">
                      <div className="selectFile1">
                        {thanksFeedBack ? (
                          <div className="thankdFeedBackDiv">
                            <div className="FeedBackDiv">🙏 Thank you for the feedback!</div>
                            <button
                              style={{ backgroundColor: partnerData.primaryColor ? partnerData.primaryColor : '#2454dd', color: 'white', borderColor: 'white' }}
                              className="Ask_Another_Doubt"
                              onClick={handleAskAnotherDoubt}
                            >
                              Ask another Doubt
                            </button>
                          </div>
                        ) : (
                          <div className="helpulDiv1">
                            <div className="helpulDiv">
                              <div>
                                <h5 className="was_helpful">Was this helpful?</h5>
                              </div>
                              <div className="feedBack-Button">
                                <button
                                  className="green-button"
                                  onClick={() => handleFeedback("Yes")}
                                >
                                  <i className="bi bi-hand-thumbs-up icon-#71e271"></i> Yes
                                </button>
                                <button
                                  className="red-button"
                                  onClick={() => handleFeedback("No")}
                                >
                                  <i
                                    className="bi bi-hand-thumbs-down icon-rgb(225, 61, 93)"
                                    style={{ marginTop: "2px" }}
                                  ></i> No
                                </button>
                              </div>
                            </div>
                            <button
                              style={{ backgroundColor: partnerData.primaryColor ? partnerData.primaryColor : '#2454dd', color: 'white', borderColor: 'white' }}
                              className="Ask_Another_Doubt"
                              onClick={handleAskAnotherDoubt}
                            >
                              Ask another Doubt
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                )}
              </>
            </>
          )}
        </div>
      ) : (
        <div>{/* <h4>Loading...</h4> */}</div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default Home;